'use strict';

$(function() {
    const locationsModule = document.querySelector("#locations-module");
    const locationsSelector = document.querySelector("#locations-selector");
    const locationsSelectorsLabels = locationsSelector.querySelectorAll(".locations-selector-label");
    const locationsMobileMaps = document.querySelectorAll(".location-map-mobile");
    const locationsPrevious = locationsSelector.querySelector("#locations-label-previous");
    const locationsNext = locationsSelector.querySelector("#locations-label-next");
    const locationBackdrop = locationsModule.querySelector("#location-backdrop");
    const maxSelectorIndex = locationsSelectorsLabels.length - 1;
    let currSelectorIndex = 1;
    if (!locationsModule) return;
    let locationId = '';

    const markers = locationsModule.querySelectorAll(".location-marker");
    const locationBoxes = locationsModule.querySelectorAll(".location-box");
    const closeButtons = locationsModule.querySelectorAll(".location-box__close");

    const updateMobileMaps = () => {
      locationsSelectorsLabels.forEach(label => {
        label.classList.remove("js-visible")
      })

      locationsMobileMaps.forEach(map => {
        map.classList.remove("js-visible")
      })
      locationsSelectorsLabels[currSelectorIndex]?.classList.add("js-visible");
      locationsMobileMaps[currSelectorIndex]?.classList.add("js-visible");

      locationsNext.disabled = currSelectorIndex === maxSelectorIndex;
      locationsPrevious.disabled = currSelectorIndex === 0;
    }

    updateMobileMaps();

    locationsPrevious.addEventListener("click", () => {
      currSelectorIndex--;
      updateMobileMaps();
    });

    locationsNext.addEventListener("click", () => {
      currSelectorIndex++;
      updateMobileMaps();
    });

    markers.forEach(marker => {
      marker.addEventListener("click", function () {
        const targetId = this.getAttribute("data-box");
        const targetBox = document.getElementById(targetId);

        if (!targetBox) return;

        markers.forEach(marker => marker.classList.remove("js-active"));
        locationBoxes.forEach(box => box.classList.remove("js-visible"));

        if (targetId !== locationId) {
          // Remove .js-visible from all location-box elements

          // Add .js-visible to the target location-box
          targetBox.classList.add("js-visible");
          this.classList.add("js-active");
          locationBackdrop.classList.add("js-visible");
          locationId = targetId;
        } else {
          targetBox.classList.remove("js-visible");
          this.classList.remove("js-active");
          locationBackdrop.classList.remove("js-visible");
        }
      });
    });

    closeButtons.forEach(button => {
      button.addEventListener("click", function () {
        locationBoxes.forEach(box => box.classList.remove("js-visible"));
        markers.forEach(marker => marker.classList.remove("js-active"));
        locationBackdrop.classList.remove("js-visible");
      });
    });

    locationBackdrop.addEventListener("click", function () {
      locationBoxes.forEach(box => box.classList.remove("js-visible"));
      markers.forEach(marker => marker.classList.remove("js-active"));
      locationBackdrop.classList.remove("js-visible");
    });
});
