'use strict';

$(function() {

  $('.panel').on('shown.bs.collapse', function(e) {
    e.stopPropagation();
    $(this).find('.panel-heading').addClass('accordion-opened');
  });

  $('.panel').on('hidden.bs.collapse', function(e) {
    e.stopPropagation();
    $(this).find('.panel-heading').removeClass('accordion-opened');
  });

  const carouselItems = document.querySelectorAll('.hero-carousel .carousel-item');

  if(carouselItems && carouselItems.length > 0) {
    function calcMobileSlide() {
      const img = document.querySelector('.hero-fake-image');
      if(!window.matchMedia('(max-width: 767px)').matches) {
        if(img) {
          img.style.height = 'auto';
        }
      } else {
        const heroSection = document.querySelector('.active .hero-img-section');
        if(img && heroSection && heroSection.offsetHeight > 0) {
          img.style.height = `${heroSection.offsetHeight}px`;
        }
      }
    }

    function handleActiveChange() {
      calcMobileSlide();
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class' && mutation.target.classList.contains('active')) {
          const activeChangeEvent = new Event('activeChange', {
            bubbles: true,
            cancelable: false,
          });

          mutation.target.dispatchEvent(activeChangeEvent);
        }
      });
    });

    carouselItems.forEach(item => {
      observer.observe(item, { attributes: true });
    });

    carouselItems.forEach(item => {
      item.addEventListener('activeChange', handleActiveChange);
    });



    if (document.querySelector('.hero-fake-image') && document.querySelector('.carousel-indicators')) {
      calcMobileSlide();
      window.addEventListener('resize', calcMobileSlide);
    }
  }

});
