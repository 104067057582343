'use strict';

(function() {
  /* ======================================== */
  /* Redirect Link                            */
  /* ======================================== */

  $('.redirect-link, .accept-redirect').on('click', function(e) {
    var _this = $(this);
    var targetUrl = _this.attr('href');
    var targetWindow = _this.data('target');

    if (_this.data('href')) {
      targetUrl = _this.data('href');
    }

    e.preventDefault();

    // Disable link to prevent double click
    _this.addClass('elem-disabled');
    redirect(targetUrl, targetWindow);
    $('.modal').modal('hide');
    setTimeout(function() {
      // Enable click again
      _this.removeClass('elem-disabled');
    }, 1000);
  });

  /* ======================================== */
  /* Popup Redirect                           */
  /* ======================================== */

  $(document).on('click', '.open-redirectmodal', function(e) {
    var _this = $(this),
      modalTitle = _this.data('title'),
      modalContentId = _this.data('id'),
      modalContent = $('#' + modalContentId).html(),
      modalHref = _this.data('href'),
      modalTarget = _this.data('target'),
      modalAccept = _this.data('accept'),
      modalDecline = _this.data('decline'),
      modal = $('.redirect-modal');

    if ($('.tiles-modal').hasClass('show')) {
      $('.tiles-modal').modal('hide');
    }

    modal.find('.modal-title').text(modalTitle);
    modal.find('.redirect-modal-content').html(modalContent);
    modal.find('.accept-redirect').html(modalAccept);
    modal.find('.decline-redirect').html(modalDecline);
    modal.find('.accept-redirect').data('href', modalHref).data('target', modalTarget);
    modal.modal('show');
  });

  // Decline Btn : Stay in the same page
  $(document).on('click', '.decline-redirect', function() {
    $('.modal').modal('hide');
    /* Empty values */
    modal.find('.modal-title').text('');
    modal.find('.modal-text').text('');
    modal.find('.accept-redirect').data('href', '').data('target', '');
  });

  function redirect(url, target) {
    window.open(url, target);
  }
})();
