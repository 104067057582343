//accesilibity management
$(document).ready(function () {

		//mobile behaviour
    var isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    var windowIsThin = window.matchMedia("(max-width:480px)").matches;

    if (isMobile || windowIsThin) {
        //carousel disabled
        $('.carousel').carousel({
            interval: false
        });

        //show search field
      
        //move sharing for small devices
        if( $('article.follow-us.social-media-sharing').length ) {
           $('article.follow-us.social-media-sharing').insertBefore($('article.col-md-4.follow-us'));
        }

    } else { };

    //accessibility
    var FONT_COOKIE = 'jj_text_size';
    var CSS_COOKIE = 'jj_access_style';
    var currentFontSize
    var currentStyle



    if (currentStyle == 'filter') {
        $("body").attr("class", "access-filter");
    }

    function switchStylestyle(cookie, currentStyle) {

        if ($("body").hasClass("access-filter")) {
            $("body").removeClass("access-filter");
        } else {
            $("body").attr("class", "access-filter");
        }

        // if (currentStyle == 'filter') {
        //     $.cookie(cookie, 'no-filter', {
        //         path: '/',
        //         expires: 10
        //     });

        // } else {
        //     $.cookie(cookie, 'filter', {
        //         path: '/',
        //         expires: 10
        //     });
        // }
    }

    function switchFont(cookie, size) {


        if (currentFontSize == null) {
            currentFontSize = 0;
        }

        //add up size but don't let it go over 2
        if (size == "larger" && currentFontSize < 2) {
            currentFontSize = (parseInt(currentFontSize) + 1);
        }

        if ((currentFontSize > 0) && size == "smaller") {
            currentFontSize = (parseInt(currentFontSize) - 1);
        }

        // //set cookie
        // $.cookie(cookie, currentFontSize, {
        //     path: '/',
        //     expires: 10
        // });

        //amend button status
        
        if (currentFontSize == 1) {
            $("body").attr("style", "font-size:110% !important");
        } else if (currentFontSize == 2) {
           $("body").attr("style", "font-size:120% !important");
        }else{
         $("body").removeAttr("style");
        }


        var divs = document.getElementsByTagName('div');
        for (var i = 0; i < divs.length; i++) {

            var hasPercentHeight = false;
            var elts = divs[i].childNodes;

            for (var j = 0; !hasPercentHeight && (j < elts.length) ; j++) {
                if (1 == elts[j].nodeType) {
                    var h = elts[j].getAttribute('height') || elts[j].style.height;
                    if (-1 != h.indexOf('%'))
                        hasPercentHeight = true;
                }
            }
            if (!hasPercentHeight) {
                var oldStyleHeight = divs[i].style.height;
                var expl = divs[i].offsetHeight;
                divs[i].style.height = 'auto';
                var auto = divs[i].offsetHeight;
                if (expl > auto)
                    divs[i].style.height = oldStyleHeight;
            }
        }
    }

});
//END accessibiliy management

function getParameter( parameterName ) {
  var queryString = window.location.search.substring(1);
  if (queryString.length==0) {return "";}
  var parameters = new Array();
  parameters = queryString.split('&');
  for(var i = 0; i < parameters.length; i++) {
    if (parameters[i].indexOf(parameterName)>=0) {
      var parameterValue = new Array();
      parameterValue = parameters[i].split('=');
      return parameterValue[1];
    }
  }
  return "";
}

