$(function() {
  const teaserComponent = $('.teaser-box');

  if (teaserComponent.length) {
    $('.teaser-box .panel-heading').matchHeight({
      property: 'min-height',
    });
    $('.teaser-box .panel-body p').matchHeight({
      property: 'min-height',
    });
    $('.teaser-box .panel-body ul.list-nav').matchHeight({
      property: 'min-height',
    });
    $('.teaser-box .panel').matchHeight({ property: 'min-height' });
  }

});
