'use strict';

$(function() {
  const iframe = document.getElementById('search-iframe');

  if(iframe) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTerm = urlParams.get('query') || iframe.getAttribute('data-search-keyword');
    const currentURL = new URL(window.location.href);
    const domain = iframe.getAttribute('data-domain') || currentURL.hostname;
    let url = `https://search.idorsia.com?domain=${domain}`;
    const category = urlParams.get('category') || '';

    if (searchTerm) {
      currentURL.searchParams.set('query', searchTerm);

      if (category) {
        currentURL.searchParams.set('category', category);
      }

      window.history.replaceState({}, document.title, currentURL);

      if (category) {
        url = `https://search.idorsia.com?domain=${domain}&q=${searchTerm}&category=${category}`;
      } else {
        url = `https://search.idorsia.com?domain=${domain}&q=${searchTerm}`;
      }
    }
    iframe.src = url;
  }
});
