'use strict';

$(function() {
  var body = $('body'),
    hasHeader = $('header').length,
    mql = window.matchMedia('(min-width: 768px)'),
    offset = 0,
    topHeaderHeight = $('.top-header').outerHeight(),
    navBarHeight = $('#navbar-collapse-1').outerHeight(),
    headerHeightDesktop = topHeaderHeight + navBarHeight,
    progressBar = $('.new-progress-bar'),
    title = '.section-title';

  if (progressBar.length) {
    // If the page has a header, add class to the progress bar
    if (hasHeader) {
      $('body').addClass('has-header');
    } else {
      progressBar.addClass('navbar-fixed-top');
    }

    // Add classes and data to the body in order to use Scrollspy
    body.addClass('relative-position');
        
    body.data({
      spy: 'scroll',
      target: '.new-progress-bar'
    });

    if (title.length) {
      $(title).each(function(i) {

        var navbar = $('.progress-bar-nav'),
          contentSection = $(this).closest('.content-section'),
          sectionParent = $(this).closest('section'),
          sectionNotContainer = !$(sectionParent).hasClass('container'),
          columnFullWidth = $(this).closest('.col-md-12'),
          isFaq = $(this).find('button'),
          sectionId = 'section_' + i,
          isPopup = $(this).closest('.popupContent');
        
        // Add an ID to the closest section to be able add the link
        if (contentSection.length && !isPopup.length) {
          contentSection.attr('id', sectionId);
        } else if (sectionParent.length && !isPopup.length) {
          sectionParent.attr('id', sectionId);
        } else if (columnFullWidth.length && !isPopup.length) {
          if (!isFaq.length) {
            columnFullWidth.attr('id', sectionId);
          }
        }
              
        // Append li to list
        var newLi = '<li><a href="#' + sectionId + '" class="nav-link">' + '<span>' + $(this).text() + '</span></a></li>';
        
        if ((contentSection.length || (sectionParent.length && sectionNotContainer) || (columnFullWidth.length && !isFaq.length)) && !isPopup.length) {
          navbar.append(newLi);
        }
      });

      // Remove arrow style on first li
      var firstArrowDiv = '<div class="first-arrow"></div>';
      progressBar
        .find('li')
        .not(':first-child')
        .find('a')
        .prepend(firstArrowDiv);

      showHideProgressBar();
    }

    // On document load
    var progressbarHeight = $('.new-progress-bar').outerHeight();
    
    setOffset(headerHeightDesktop, progressbarHeight);
    
    $('html').css({
      'scroll-behavior': 'smooth'
    });

    addMarginToMainSection(offset);
    adjustScrollPaddingTop(offset);

    // Window Scroll
    if (hasHeader) {
      var lastScrollTop = 0;

      $(window).scroll(function() {
        var scroll = $(window).scrollTop(),
          scrollPaddingTop;

        if (scroll > lastScrollTop) {
          scrollPaddingTop = navBarHeight + progressbarHeight;
        } else {
          scrollPaddingTop = headerHeightDesktop + progressbarHeight;
        }

        lastScrollTop = scroll;
        adjustScrollPaddingTop(scrollPaddingTop);
      });
    }

    // Initialize ScrollSpy
    $('body').scrollspy({
      offset: offset + 20 // Have to add + 20 here to make it work
    });
  }

  // Set Offset function
  function setOffset(headerHeight, progressBarHeight) {
    if (hasHeader) {
      offset = headerHeight + progressBarHeight;
    } else {
      offset = progressBarHeight;
    }

    offset += 10; // Added 10 to add a bit of space above the title when scrolling
  }

  // Add margin to Main tag when progress bar is defined
  function addMarginToMainSection(val) {
    if (mql.matches) {
      if (!hasHeader) {
        $('main').css('margin-top', val);
      }
    } else {
      if (!hasHeader) {
        $('main').css('margin-top', '0');
      }
    }
  }

  // Add Scroll Padding Top
  function adjustScrollPaddingTop(offset) {
    $('html').css({
      'scroll-padding-top': offset
    });
  }

  function showHideProgressBar() {
    if(mql.matches) {
      progressBar.addClass('show');
    } else {
      progressBar.removeClass('show');
    }
  }

  // Adjust margin top if it is mobile or not
  window.addEventListener('resize', function() {
    addMarginToMainSection();
    showHideProgressBar();
  });

});

