'use strict';

$(function() {
  const textImgDiv = '.text-image';

  // On Load
  revealDiv(textImgDiv);

  // On Scroll
  window.addEventListener('scroll', function() {
    revealDiv(textImgDiv);
  }, false);

});