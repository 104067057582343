
/* ==========================================================================
   Function: revealDiv - Reveal a div on load and scroll
   ========================================================================== */
$(function() {
  window.revealDiv = (div) => {
    const divs = document.querySelectorAll(div);

    for (let i = 0; i < divs.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = divs[i].getBoundingClientRect().top;
      const elementVisible = 50;
      const elementHeight = divs[i].clientHeight;

      if (elementTop > windowHeight - elementVisible || elementTop < 0 && Math.abs(elementTop) > elementHeight - elementVisible) {
        divs[i].classList.remove('active');
      } else {
        divs[i].classList.add('active');
      }
    }
  }

  // If the page has a scroll bar, add class to the body (fixes the header moving when opening a modal)
  function addScrollbarClass() {
    var mql = window.matchMedia('(min-width: 769px)');
    if (mql.matches) {
      if ($(document).height() > $(window).height()) {
        $('body').addClass('has-scrollbar');
      }
    } else {
      $('body').removeClass('has-scrollbar');
    }
  }

  addScrollbarClass();

  $(window).on('resize', function() {
    addScrollbarClass();
  });
});
