$(document).ready(function() {
  $('#login_form').on('submit', function(e) {
    if ($(this).find('input[name="consent"]').length) {
  		if ($(this).find('input[name="consent"]')[0].checked === false) {
          	$('.alert-warning').css("background-color", "#f30");
            $('.control-label').css("color", "black");
      		return false;
    	}
    }
  });
});