'use strict';

$(function() {
  const mql = window.matchMedia('(min-width: 768px)');
  
  // On load
  checkNavHeightAndApplyStyle();

  // On resize
  window.addEventListener('resize', checkNavHeightAndApplyStyle);

  $('.modal').on('shown.bs.modal', function() {
    checkNavHeightAndApplyStyle();
  });

  // Check Nav Tab height and apply the right style
  function checkNavHeightAndApplyStyle() {
    
    $('.nav-tabs').each(function() {
      const navTabsHeight = $(this).height();
      let isActiveTab = $(this).find('.nav-link.active');
      
      if (!isActiveTab.length) {
        const firstLiTab = $(this).find('.nav-item:first-child');
        isActiveTab = $(firstLiTab).find('.nav-link').addClass('active');
      }

      if (navTabsHeight < 50) {
        $(this).removeClass('normal-style');
        styleActiveTab(isActiveTab);
      } else {
        $(this).addClass('normal-style');
      }

      // Event fired when click on a tab
      $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        const activeTab = e.target;
        const previousTab = e.relatedTarget;
        
        $(activeTab).addClass('active');
        $(previousTab).removeClass('active');
    
        // Style active tab on click
        styleActiveTab(activeTab);
      });
    });
  }

  // Style Tab when it is active
  function styleActiveTab(tab) {
    const tabWidth = $(tab).outerWidth() + 'px';
    const currentLi = tab.closest('li');
    const navTabs = tab.closest('.nav-tabs');
    let liPositionLeft = $(currentLi).position().left + 10;

    $(navTabs).find('.border').css({
      'width': tabWidth,
      'left': liPositionLeft
    });
  }

});