'use strict';

$(function() {
  var socialMediaComponent = $('.social-media-feeds');
  
  socialMediaComponent.each(function(i, v) {
    var carouselItem = $(v).find('.carousel-item'),
      isTwitterComponent = socialMediaComponent.find('.twitter-feed');
    
    // Limit text to 280 characters
    carouselItem.each(function(j, w) {
      var charLimit = 280,
        text = $(this).find('.post-body p'),
        trimedText = text.text().replace(/\s+/g, ' ').trim();
      
      if (isTwitterComponent) {
        text.find('a').remove();
      }

      if (trimedText.length > 280) {
        trimedText = trimedText.substr(0, charLimit);
        trimedText = trimedText.substr(0, Math.min(trimedText.length, trimedText.lastIndexOf(' ')));
        $(this).find('.post-body p').text(trimedText + '...');
      }
    });
  });
  
  window.addEventListener('load', getHeight);

  var rtime;
  var timeout = false;
  var delta = 200;

  $(window).resize(function() {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      getHeight();
    }
  }

  function getHeight() {
    var maxHeight = 0,
      socialMediaComponent = $('.social-media-feeds .carousel'),
      mq = window.matchMedia('(min-width: 992px)');
    
    if (mq.matches) {
      socialMediaComponent.each(function(i, v) {
        var thisHeight = $(v).outerHeight();

        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
      });
      
      $(socialMediaComponent).css('height', maxHeight);
    } else {
      $(socialMediaComponent).css('height', '');
    }
  }
});
