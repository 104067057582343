(function() {
  const tileBtn = $('.tile-btn');
  const gridDisplay = $('.grid-display');
  const modal = $('.tiles-modal');
  const modalTitle = modal.find('.modal-title');
  const modalContent = modal.find('.modal-body');
  let popupVideoWidth = 0;

  // Empty modal when closing it
  $('.tiles-modal').on('hidden.bs.modal', function() {
    const modalDialog = $(this).find('.modal-dialog-centered');
    modalDialog.css('max-width', '');
    if (!$(this).hasClass('redirect-modal')) {
      $(modalContent).empty();
      if (modalContent.hasClass('img-left')) {
        modalContent.removeClass('img-left');
      }
    }
    if (modal.hasClass('has-video')) {
      modal.removeClass('has-video');
    }
  });

  $(tileBtn).each(function() {
    const $thisBtn = $(this);

    // When click on a tile, opens popup and inject content
    $($thisBtn).on( 'click', function() {
      const title = $thisBtn.find('strong').text();
      const popupContent = $thisBtn.next('.popupContent');
      const imgAlignment = popupContent.data('img-alignment');
      const isFaq = popupContent.find('.content-faq');
      const contentVideo = popupContent.find('.process-video');

      if(contentVideo.length) {
        setVideoModalWidth(contentVideo);
        modal.addClass('has-video');
      }

      modalTitle.text(title);

      if (popupContent.length) {
        modalContent.append(popupContent.html());
        $('.modal-content .redirect-link, .modal-content .accept-redirect').on('click', function(e) {
          var _this = $(this);
          var targetUrl = _this.attr('href');
          var targetWindow = _this.data('target');

          if (_this.data('href')) {
            targetUrl = _this.data('href');
          }

          e.preventDefault();

          // Disable link to prevent double click
          _this.addClass('elem-disabled');
          window.open(targetUrl, targetWindow);
          $('.modal').modal('hide');
          setTimeout(function() {
            // Enable click again
            _this.removeClass('elem-disabled');
          }, 1000);
        });
      }

      if (imgAlignment) {
        modalContent.addClass('img-left');
      }

      if (isFaq.length) {
        modalContent.addClass('flex-column');
      }

      // If tiles section has a theme color, send the theme color to the popup
      const themeColor = $thisBtn.closest('section').attr('class');

      if (themeColor) {
        modal.addClass(themeColor);
      }

    });
  });

  var grids = document.querySelectorAll('.grid-display');
  if (grids) {
    for (var i = 0; i < grids.length; i++) {
      var grid = grids[i];
      new Masonry( grid, {
        itemSelector: '.tile'
      });
    }
  }


  $(document).on('click', function(event) {
    const target = $(event.target);
    const specialDiv = $('.modal-content');

    if (!target.closest(specialDiv).length && specialDiv.is(':visible')) {
      $('.modal').modal('hide');
    }
  });

  function setVideoModalWidth(videoDiv) {
    const vimeoVideo = $(videoDiv).find('.process-video__iframe');
    let modalWidth = 0;

    popupVideoWidth = vimeoVideo.attr('width');

    // When video is open in a modal, adapt modal size
    const modal = $('.tiles-modal').find('.modal-dialog-centered');

    if (!popupVideoWidth) {
      popupVideoWidth = ''; // don't need to set a width here, the video will take the max width allowed by the popup
    }

    modalWidth = parseInt(popupVideoWidth) + 40;
    modal.css('maxWidth', modalWidth + 'px'); // 40 is the padding left and right
  }
})();
