/*** Generic Text Component: START ***/
(function() {
  const ulWrapper = $('.generic-text').find('ul li');
  const olWrapper = $('.generic-text').find('ol li');
  const tableWrapper = $('.generic-text').find('table');

  function modifyList(listWrapper = []) {
    listWrapper.each(function(i, obj) {
      if ($(this).has('p').length === 0) {
        const text = $(this).html();
        text.trim() !== '' && $(this).empty().append(`<p>${text}</p>`);
      }
    });
  }

  if (ulWrapper) {
    modifyList(ulWrapper);
  }
  if (olWrapper) {
    modifyList(olWrapper);
  }
  if (tableWrapper) {
    tableWrapper.each(function() {
      const rows = $(this).html();
      const markup = `<div class="table-responsive my-4"><table>${rows}</table></div>`;
      $(this).empty().before(markup);
    });
    tableWrapper.remove();
  }
})();
/*** Generic Text Component: END ***/
