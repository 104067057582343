function redirectToExternalUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const externalUrl = urlParams.get("externalUrl");
  const timer = parseInt(urlParams.get("timer"));

  if (externalUrl && !isNaN(timer) && timer > 0) {
    setTimeout(() => {
      window.location.href = externalUrl;
    }, timer * 1000);
  }
}

function enhanceLink(link) {
  const href = link.href;
  const externalUrlValue = link.getAttribute("data-external-url");
  const timer = link.getAttribute("data-timer") || 30;

  if (externalUrlValue && timer) {
    const url = `${href}?externalUrl=${externalUrlValue}&timer=${timer}`;
    link.setAttribute("href", url);
  }
}

function linkEnhance() {
  if (!window) return;

  const links = document.getElementsByTagName("a");

  for (let link of links) {
    const externalUrlValue = link.getAttribute("data-external-url");
    if (link.href && externalUrlValue) {
      enhanceLink(link);
    }
  }

  redirectToExternalUrl();
}

function addLoadEvent(func) {
  const oldonload = window.onload;
  if (typeof window.onload !== "function") {
    window.onload = func;
  } else {
    window.onload = () => {
      if (oldonload) {
        oldonload();
      }
      func();
    };
  }
}

addLoadEvent(linkEnhance);
