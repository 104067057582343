
function redirectMe() {
	  var url = window.location.pathname;
  	var refer = document.referrer.split('/')[2];
  	var orig = window.location.origin.split('/')[2];

  	if (refer == orig) {
      //Have to use includes, as the templates of the URL is different.
      if ((url.startsWith("/system-pages/external.html")) || (url.startsWith("/iw-preview")) || (url.includes("/system-pages/external.html"))) {
          var targetUrl = getUrlParameter("target");
          if (validURL(targetUrl)) {
              location.href = targetUrl;
          }
      }
	}
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

var time = setTimeout( redirectMe, 5000 );
