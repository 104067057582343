$(function() {

  const disclaimerDiv = $('.disclaimer').length;

  if (disclaimerDiv) {

    // Click on Confirm button
    // $('.confirmBtn').click(function(e) {
    //   e.preventDefault();
    //
    //   const consentCheckbox = $('.consent');
    //
    //   if(consentCheckbox.is(':checked')) {
    //     window.location = $('.approveUrl').val();
    //   } else {
    //     const disclaimerBox = $('.alert-disclaimer');
    //     disclaimerBox.addClass('error');
    //     disclaimerBox.find('.checkbox-container').addClass('error');
    //     disclaimerBox.find('.error').addClass('show');
    //   }
    // });

    // Click on Decline button
    $('.declineBtn').click(function() {
      window.location = $('.declineUrl').val();
    });
  }
});
