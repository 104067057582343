'use strict';

$(function() {
  const quoteDiv = '.quote';
  
  // On Load
  revealDiv(quoteDiv);
  selectBackgroundImage();

  // On Scroll
  window.addEventListener('scroll', function() {
    revealDiv(quoteDiv);
  }, false);

  // On Resize
  window.addEventListener('resize', selectBackgroundImage);

  // Modal shown
  $('.modal').on('shown.bs.modal', function() {
    revealDiv(quoteDiv);
  });

  // For the Quote POC, to remove if not used
  function selectBackgroundImage() {
    const mql = window.matchMedia('(min-width: 768px)');
    const quoteComponent = $('.quote');
  
    quoteComponent.each(function() {
      const $this = $(this);
      const backgroundImgMobile = $this.data('background-mobile');
      const backgroundImgDesktop = $this.data('background-desktop');
    
      if(backgroundImgDesktop) {
        if (mql.matches) {
          $this.css('background-image', 'url("' + backgroundImgDesktop + '")');
        } else {
          $this.css('background-image', 'url("' + backgroundImgMobile + '")');
        }
      }
    });
  }
});