'use strict';

$(function() {
  var header = $('.navbar-default'),
    headerHeight = header.outerHeight(),
    progressBar = $('.new-progress-bar'),
    progressBarExists = progressBar.data ? progressBar.data('editMode') !== true && progressBar.length : null;

  let mainWrapper = $('main');
  let oldValue = 0;
  let newValue = 0;
  let mql = window.matchMedia('(min-width: 768px)');

  // Add active class to menu item
  // addActiveClass();
  addMarginToMain(`${headerHeight}px`);
  window.addEventListener('resize', function() {
    addMarginToMain(`${headerHeight}px`);
  });

  if (progressBarExists) {
    progressBar.css('top', headerHeight);
    header.addClass('no-shadow');
    headerHeight += progressBar.outerHeight();
  }

  var doc = document.documentElement;
  var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  // Test header and progressbar position on page load
  if (top > 0) {
    showHeaderProgressbarOnScrollDown();
  }

  $(window).scroll(function() {
    newValue = window.pageYOffset;

    // Get the maximum scrollable height
    var maxScroll = $(document).height() - $(window).height();

    // Prevent out of frame values due to Safari bounce effect
    if (oldValue < 0) oldValue = 0;
    if (newValue < 0) newValue = 0;
    if (oldValue > maxScroll) oldValue = maxScroll;
    if (newValue > maxScroll) newValue = maxScroll;

    if (oldValue < newValue) {
      // On scroll down
      showHeaderProgressbarOnScrollDown();
    } else if (newValue < maxScroll) {
      // On scroll up

      header.addClass('fixed-header-up');
      header.removeClass('fixed-header-translate');
      header.css('transform', '');
      if (header.length && progressBarExists) {
        var progressBarHeight = progressBar.outerHeight();
        headerHeight = header.height() + progressBarHeight;

        progressBar.addClass('fixed-bar-up');
        progressBar.removeClass('fixed-bar');
        progressBar.css('top', `${header.height()}px`);
      }
      // Remove classes if header at the top
      if (newValue <= 0) {
        header.removeClass('fixed-header-up');
        header.removeClass('fixed-header-translate');
        header.css('transform', '');
        if (header.length && progressBarExists) {
          progressBar.removeClass('fixed-bar-up');
          progressBar.removeClass('fixed-bar');
          progressBar.css('top', `${header.height()}px`);
        }
      }
      addMarginToMain(`${headerHeight}px`);
    }
    oldValue = newValue;
  });

  if (progressBarExists) {
    $('header').addClass('no-margin');
  }

  function addMarginToMain(height) {
    if (mql.matches) {
      mainWrapper.css('margin-top', height);
    } else {
      mainWrapper.css('margin-top', '');
    }
  }

  function showHeaderProgressbarOnScrollDown() {
    console.log('showHeaderProgressbarOnScrollDown');

    var navBarHeight = $('.navbar-collapse').outerHeight();
    var progressBarHeight = $('.new-progress-bar').outerHeight();
    var navbarTopHeight = $('.top-header').outerHeight();

    header.addClass('fixed-header-translate');
    header.css('transform', `translateY(-${navbarTopHeight}px)`);
    header.removeClass('fixed-header-up');

    if (header.length && progressBarExists) {
      progressBar.addClass('fixed-bar');
      progressBar.css('top', `${navBarHeight - 1}px`);
      progressBar.removeClass('fixed-bar-up');
    }

    if (progressBarExists) {
      addMarginToMain(`${navBarHeight + progressBarHeight}px`);
    } else {
      addMarginToMain(`${navBarHeight}px`);
    }
  }

  function addActiveClass() {
    const primaryNavbarLi = $('.navbar-nav.level-1 > li');
    const pathname = window.location.pathname;

    primaryNavbarLi.each(function(i, val) {
      const hrefLink = $(val).find('a').attr('href');

      $(val).removeClass('active');

      if (hrefLink === pathname) {
        $(val).addClass('active');
      }
    });
  }
});

/*** Header Component: START ***/
(function() {
  const $topnavLI = $('.top-nav-actions > li');
  const $topnavItems = $topnavLI.find('.top-nav-submenu');
  const shareWindowSize = 'width=600,height=460';
  const currentLocation = encodeURIComponent(window.location.href);
  const title = 'share';

  const $topNavCloseBtn = $('.top-nav-close-btn');

  function closePanel() {
    $topnavItems.removeClass('d-block');
    $topnavLI.removeClass('active');
  }

  $topNavCloseBtn.on('click', function(e) {
    e.preventDefault();

    closePanel();
  });

  $('.top-nav-actions > li a.tp-link').on('click', function(e) {
    e.preventDefault();
    const $parent = $(this).parent();
    const subItem = $parent.find('.top-nav-submenu');

    if (subItem.hasClass('d-block')) {
      closePanel();
    } else {
      closePanel();
      subItem.addClass('d-block');
      $parent.addClass('active');
    }
  });

  // For mobile overview toggle

  const $mobileOverview = $('.sub-menu-mobile > .summary > p');

  $('.sub-menu-mobile > .summary > span').on('click', function(e) {
    e.preventDefault();
    const $parent = $(this).parent();
    const subItem = $parent.find('p');

    if (subItem.hasClass('d-block')) {
      $mobileOverview.removeClass('d-block');
    } else {
      $mobileOverview.removeClass('d-block');
      subItem.addClass('d-block');
    }
  });

  $('.follow-us .share-links').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);

    const urlMapping = {
      facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
      twitter: 'https://twitter.com/intent/tweet?text=',
      linkedIn: 'https://www.linkedin.com/sharing/share-offsite/?mini=false&url=',
      mail: 'mailto:?subject=Look at this website&body=Hi,I found this website and thought you might like it: ',
    };

    window.open(urlMapping[$this.data('title')] + currentLocation, title, shareWindowSize);
  });
})();
/*** Header Component: END ***/

/*** Header Contact Section: START ***/
(function() {
  const $panelOne = $('#contact-panel-one');
  const $panelTwo = $('#contact-panel-two');
  let isLastPage = false;

  $('input[type=radio][name=contactType]').on('change', function() {
    if ($('input[type=radio][name=contactType]').is(':checked')) {
      $('#goNextBtn').removeAttr('disabled');
    } else {
      $('#goNextBtn').attr('disabled', 'disabled');
    }
  });

  function navigateToPage($element) {
    $element.data('url');
    window.open($element.data('url'), '_self');
  }

  $('#goNextBtn').on('click', function() {
    const $currentCheckoxParent = $('input[type=radio][name=contactType]:checked').parent().parent().parent();

    if (isLastPage) {
      navigateToPage($currentCheckoxParent);
      return;
    }
    const $infoDiv = $currentCheckoxParent.find('.tp-contact-box__formItem__info');
    if ($infoDiv.length > 0) {
      $panelTwo.empty().append($infoDiv.html());
      $panelOne.css('display', 'none');
      $panelTwo.css('display', 'block');
      $('#goPrevBtn').removeClass('d-none');
      $('#goPrevBtn').addClass('d-block');
      isLastPage = true;
      return;
    } else {
      // if contact has no description div attached
      navigateToPage($currentCheckoxParent);
    }
  });

  $('#goPrevBtn').on('click', function() {
    if ($panelTwo.html().length > 0) {
      $panelOne.css('display', 'flex');
      $panelTwo.css('display', 'none');
      $panelTwo.empty();
      $(this).removeClass('d-block');
      $(this).addClass('d-none');
      isLastPage = false;
    }
  });
})();
/*** HEader Contact Section: End ***/
