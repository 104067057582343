'use strict';

let mql = window.matchMedia('(min-width: 768px)');

$(function() {
  const videoComponent = $('.process-video');

  $(videoComponent).each(function( index, item ) {
    const vimeoVideo = $(item).find('.process-video__iframe');
    const videoImg = $(item).find('.process-video__image');
    let videoWidth = vimeoVideo.attr('width');
    const videoPlay = $(item).find('.process-video__play, .process-video__image');

    if (vimeoVideo && vimeoVideo[0]) {
      const videoIframePlayer = new Vimeo.Player(vimeoVideo[0]);

      if (!videoWidth.length) {
        videoWidth = vimeoVideo.outerWidth();
      }

      if (videoImg.length && videoImg.attr('src').length > 0 && mql.matches) {
        videoPlay.addClass('show');

        $('.process-video__image').css({
          'width': videoWidth
        });
      }

      $(videoPlay).on('click', function () {
        videoPlay.removeClass('show');
        videoIframePlayer.play();
      });
    }
  });
});
