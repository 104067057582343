$(document).ready(function(){
  var filter = getUrlParameter('filter');

  if (filter == '') {
    $('#mAdhoc').removeClass("active");
    $('#mOther').removeClass("active");
    $('#mAll').addClass("active");
  } else if (filter == 'adhoc') {
    $('#mAdhoc').addClass("active");
    $('#mOther').removeClass("active");
    $('#mAll').removeClass("active");
    $('div#adhoc').show();
    $('div#other').hide();
  } else if (filter == 'other') {
    $('#mAdhoc').removeClass("active");
    $('#mOther').addClass("active");
    $('#mAll').removeClass("active");
    $('div#adhoc').hide();
    $('div#other').show();
  }

});

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};